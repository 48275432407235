const authRoute = [
    {
        path: '/',
        name: 'landing',
        component: () => import('@/views/Landing/Index'),
        meta: {
            guest: true
        }
    },
    {
        path: '/login',
        name: 'login',
        redirect: '/',
        // component: () => import('@/views/Auth/Login'),
        meta: {
            guest: true
        }
    },
    {
        path: '/admin/login',
        name: 'admin-login',
        component: () => import('@/views/AdminManager/LoginAdmin'),
        meta: {
            guest: true
        }
    },
    {
        path: '/register',
        name: 'register',
        redirect: '/',
        // component: () => import('@/views/Auth/Register'),
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        redirect: '/',
        // component: () => import('@/views/Auth/ForgotPassword'),
        meta: {
            guest: true
        }
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import('@/views/Auth/CallBack'),
        meta: {
            guest: true
        }
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: () => import('@/views/Auth/Redirect'),
        meta: {
            guest: true
        }
    },
];

export default authRoute;